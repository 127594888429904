import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {  }

  submit(event) {
    this.element.querySelectorAll('input[type="text"], input[type="date"], select, textarea').forEach(element => {
      element.readOnly = true;
      element.classList.add("refreshing");
    });

    Turbo.navigator.submitForm(this.element, this.element);
  }
}
