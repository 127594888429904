import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { CachedJSON } from "~/src/office/cached_json"
import { make_tree, preserve_tree_element_scroll_position } from "~/src/office/tree"
import { ProgressOverlay } from "~/src/office/progress_overlay"

export default class extends Controller {
  static values = { selected: String }

  connect() {
    CachedJSON.fetch("/table_of_contents.json", this.render_articles_toc);
  }

  render_articles_toc(tree_data){
    var tree = ObjectRegistry.set('table-of-contents',
                                  make_tree(tree_data));
    var lang = I18n.locale();
    tree.each(function() {
      this.data = {'id': this.id};
    });

    tree.render('#table-of-contents', {
      onclick: function(node, event) {
        ProgressOverlay.start();
        var params = $.parseParams(location.search.slice(1));
        params['heading_id'] = node.id;
        preserve_tree_element_scroll_position('table-of-contents', {'id': $(this).data('id')});
        Turbo.visit('?' + $.param(params));
      },

      node_title: function() {
        return this["grade_text"] + " " + this["text_"+lang];
      },

      render_condition: function() {
        return this.grade <= 3;
      }
    });
    tree.selectCurrentBy({'id': $('#table-of-contents').data('selected')});
  }
}
