import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).datepicker({
      format: "dd.mm.yyyy",
      language: I18n.locale(),
      autoclose: true
    }).attr("autocomplete", "off");
  }
}
