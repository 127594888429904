import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    Dropzone.autoDiscover = false;
  }

  connect() {
    var zone = new Dropzone(this.element);
  }
}
