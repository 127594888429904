import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    hash: String,
    size: String
  }

  connect() {
    if ($(this.element).has('img').length == 0) {
      this.makeIdenticon();
    }
  }

  makeIdenticon() {
    var size = this.sizeValue;
    var data = new Identicon(this.hashValue, size).toString();
    $(this.element).append($('<img width='+ size +' height='+ size +' src="data:image/png;base64,' + data + '">'));
  }
}
