import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.init_histogram();
  }

  init_histogram(){
    var histogram = {
      template: Handlebars.compile('\
        <span class="{{#if active}}active{{/if}}" style="left:{{left}}%;right:{{right}}%;height:{{height}}px"></span>\
      '),

      blockHeight: 2,
      groupArticleCount: 4,
      maxHeight: 28,
      getHeight: function(occurrences) {
        var height = Math.ceil(occurrences / this.groupArticleCount) * this.blockHeight;
        if (height > this.maxHeight) { height = this.maxHeight; }
        return height;
      },

      leftOffset: function(monthstamp) {
        return histogram.spectrum.toStepping(monthstamp);
      },
      rightOffset: function(monthstamp) {
        return 100 - histogram.spectrum.toStepping(monthstamp);
      },

      indicator: {
        template: Handlebars.compile('\
          <div class="range" style="left:{{left}}%;right:{{right}}%;"></div>\
        '),

        update: function(bound, offset, target) {
          this.element.css(bound, offset + "%");
          if($(target).hasClass("noUi-active")) {
            this.element.removeClass("animated");
          } else {
            this.element.addClass("animated");
          }
        },

        init: function(container) {
          this.element = $(this.template({ left: 1, right: 1 }));
          container.append(this.element);
        }
      },

      init: function(data) {
        var container = $(".noUi-pips");
        this.spectrum = $('#slider-date')[0].getInfo()[0];
        this.indicator.init(container);

        var self = this;
        $.each(data, function(i, article) {
          var monthstamp = Monthstamp.fromDate(article.month);
          container.append(self.template({
            height: self.getHeight(article.amount),
            left: histogram.leftOffset(monthstamp - 1),
            right: self.rightOffset(monthstamp),
            active: article.active
          }));
        });
      }
    }

    var slider = $("#date-slider");
    if (slider.length > 0) {
      var params = $.parseParams(location.search.slice(1));
      $("#slider-date").noUiSlider({
        connect: true,
        range: {
          min: Monthstamp.fromDate(slider.data("date-min")),
          max: Monthstamp.fromDate(slider.data("date-max"))
        },
        step: 1,
        start: [ Monthstamp.fromDate(slider.data("date-start")),
                 Monthstamp.fromDate(slider.data("date-stop")) ],
        format: wNumb({ decimals: 0 }),
      }).on({
        set: function(e, data) {
          params['start_date'] = Monthstamp.toISOString(data[0]);
          params['stop_date'] = Monthstamp.toISOString(data[1], true);
          Turbo.visit('?' + $.param(params));
        }
      });

      $("#slider-date").noUiSlider_pips({
        mode: 'positions',
        density: 3, // Change this to show more lines between years
        values: [0,10,20,30,40,50,60,70,80,90,100],
        format: {
          to: function(monthstamp){
            return Monthstamp.toDate(monthstamp).getFullYear();
          }
        }
      });

      histogram.init(timelineResultData);

      function setDate( value, target ){
        $(this).html(Monthstamp.toReadableString(value));
      }

      $("#slider-date").Link('lower').to($("#date-slider .date-start"), setDate);
      $("#slider-date").Link('upper').to($("#date-slider .date-stop"), setDate);

      $("#slider-date").Link('lower').to($("#date-slider .date-start"), function(monthstamp, target){
        histogram.indicator.update("left", histogram.leftOffset(monthstamp - 1), target);
      });
      $("#slider-date").Link('upper').to($("#date-slider .date-stop"), function(monthstamp, target){
        histogram.indicator.update("right", histogram.rightOffset(monthstamp), target);
      });
    }
  }
}
