export const ProgressOverlay = function(){
  return {
    isEnabled: function(){
      return false;
    },

    start: function(){
      if (this.isEnabled()) {
        $("#loading-in-progress").fadeIn({easing: "easeInQuint",
                                          duration: 500});
      }
    }
  };
}();
