import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if(!$("#erb-editor").length)
	return

    var editor = ace.edit("erb-editor");
    var textarea = $('textarea[name="page[content]"]').hide();

    editor.$blockScrolling = Infinity;
    editor.session.setUseWrapMode(true);
    editor.setOptions({
	tabSize: 2,
	useSoftTabs: true,
	indentedSoftWrap: false,
	maxLines: 30
    });

    var ERBMode = ace.require("ace/mode/html_ruby").Mode;
    editor.session.setMode(new ERBMode());

    editor.setTheme("ace/theme/chrome");

    editor.session.setValue(textarea.val());
    editor.session.on('change', function(){
	textarea.val(editor.session.getValue());
    });
  }
}
