export const CachedJSON = function() {
  var store = LocaleBasedStore();

  var loadJSON = function(path, callback) {
    $.getJSON(path, function(result) {
      store.set(path, result);
      callback(result);
    });
  };

  return {
    fetch: function(path, callback) {
      if(store.has(path)) {
        callback(store.get(path));
      } else {
        loadJSON(path, callback);
      }
    },
  };
}();
