export function make_tree(nodes) {
  return new Tree(nodes);
}

export function preserve_tree_element_scroll_position(tree_name, condition) {
  var link = $(ObjectRegistry.get(tree_name).findBy(condition).link);
  var relative_position = link.position().top - $(window).scrollTop();

  $(document).one('turbo:load', function() {
    var link = $(ObjectRegistry.get(tree_name).findBy(condition).link);
    $(window).scrollTop(link.position().top - relative_position);
  });
}

function Tree(nodes) {
  nodes = jQuery.extend(true, [], nodes);
  var tree_root;
  var tree = this;

  this.render = function(selector, configuration) {
    tree_root = $(selector);
    configuration = $.extend(true, {
      'render_condition': function(){ return true; },
      'fold_condition': function(){ return false; },
      'node_title': function(){ return this.text; },
      'onclick': function(node, event){ }
    }, configuration);

    function render_node($container) {
      if(!configuration.render_condition.apply(this)) {
        return;
      }

      var $list_item = $('<li />');
      $container.append($list_item);
      var $link = $('<a href="#" />').text(configuration.node_title.apply(this));
      if(configuration.fold_condition.apply(this)) {
        $link.hide();
      }

      $list_item.append($link);

      for(var key in this.data) {
        $link.attr('data-'.concat(key), this.data[key]);
      }

      $link.click(function(event) {
        event.preventDefault();
        configuration.onclick.apply(this, [$(this).data('tree-node'), event]);
      });

      $link.data('tree-node', this);
      this['link'] = $link;

      if (this.nodes && this.nodes.length > 0) {
        var $sublist = $('<ul />');
        $list_item.append($sublist);
        $(this.nodes).each(function() {
          render_node.apply(this, [$sublist]);
        });
      }
    }
    $(this.nodes()).each(function() {
      render_node.apply(this, [tree_root]);
    });
  };

  this.nodes = function() {
    return nodes;
  };

  this.each = function(callback) {
    function recurse(depth) {
      callback.apply(this, [depth]);
      $(this.nodes).each(function() {recurse.apply(this, [depth + 1]);});
    }
    $(this.nodes()).each(function() {recurse.apply(this, [0]);});
  };

  this.mapNode = function(node, callback) {
    var recurse = function(node) {
      return [callback.apply(node)].concat($.map(node.nodes, recurse));
    };
    return recurse(node);
  };

  this.eachBy = function(condition, callback, excludeInvisible) {
    this.each(function() {
      if(!excludeInvisible && !this.link) {
        return;
      }
      for(var name in condition) {
        if (this[name] != condition[name]) {
          return;
        }
      }
      return callback.apply(this);
    });
  };

  this.findBy = function(condition, excludeInvisible) {
    var node = null;
    this.eachBy(condition, function() {
      node = this;
      return false;
    }, excludeInvisible);
    return node;
  };

  this.selectCurrent = function(link) {
    tree_root.find('.current').map(function() {
      $(this).removeClass('current');
    });

    link.addClass('current');
    link.parent('li:first').addClass('current');

    link.parents('li').find('>a').show();
    link.parent('li:first').find('>ul>li>a').show();
  };

  this.selectCurrentBy = function(condition) {
    this.eachBy(condition, function() {
      tree.selectCurrent(this.link);
    });
  };

  this.each(function(depth) {
    this.depth = depth;
  });
}
