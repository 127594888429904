import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { CachedJSON } from "~/src/office/cached_json"
import { make_tree, preserve_tree_element_scroll_position } from "~/src/office/tree"
import { ProgressOverlay } from "~/src/office/progress_overlay"

export default class extends Controller {
  static values = { selected: String }

  connect() {
    CachedJSON.fetch("/classification_schema.json", this.render_topics_tree);
  }

  render_topics_tree(tree_data) {
    var selected_code = $('#classification-tree').data('selected');
    var tree = ObjectRegistry.set('classification-tree',
                                  make_tree(tree_data));

    function isUncle(node) {
      if(!selected_code) {
        return false;
      }
      return selected_code.toString().indexOf(node.code.slice(0, -1)) === 0
    }

    tree.each(function() {
      this.data = {'code': this.code};
    });

    tree.render('#classification-tree', {
      onclick: function(node, event) {
        ProgressOverlay.start();
        preserve_tree_element_scroll_position('classification-tree', {'code': $(this).data('code')});
        var params = $.parseParams(location.search.slice(1));
        params["margrid[references][page]"] = 1
        params["code"] = node.code
        Turbo.visit('?' + $.param(params));
      },

      fold_condition: function() {
        if(isUncle(this)) {
          return false;
        }
        return this.depth > 1;
      }
    });

    tree.each(function() {
      if(this.link) {
        this.link.attr('title', this.description);
      }
    });

    tree.selectCurrentBy({'code': selected_code});
  }
}
