import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initializeSelect2();
  }

  initializeSelect2(){
    var sequence_select = $(this.element);
    if(sequence_select.length > 0){
      var except = $.map($(".sequence-number"), function(seq){
        return $.trim($(seq).text());
      })

      sequence_select.select2({
        ajax: {
          url: "/api/sequences.json",
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              query: params.term,
              except: except
            };
          },
          processResults: function (data, params) {
            return {
              results: data.sequences,
            };
          },
          cache: true
        },
        escapeMarkup: function (markup) { return markup; },
        minimumInputLength: 2,
      });
    }
  }
}
