import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.termRegister = ObjectRegistry.get('term-register');
    this.personRegister = ObjectRegistry.get('person-register');
  }

  connect() {
    this.initArticleReferences()
    this.initGlossaryReferences()
    this.initDossierReferences()
    this.initPersonReferences()
  }

  initArticleReferences() {
    $(this.element).find("span.article-reference").each(function(i, e) {
      var locale = $("body").data('locale');
      e = $(e);
      if(locale == 'de'){
        e.replaceWith("<a href=\"/articles/"+ e.data("term") +"\">"+ e.text() +"</a>")
      } else {
        e.replaceWith("<a href=\"/"+ locale  + "/articles/"+ e.data("term") +"\">"+ e.text() +"</a>")
      }
    });
  }

  initGlossaryReferences() {
    var self = this;
    self.termRegister.ready(function() {
      $(self.element).find("span.glossary-reference").each(function(i, e){
        e = $(e);
        var title = self.termRegister.title(e.data("term"));
        if (title) {
          e.attr("data-content", self.termRegister.definition(e.data("term"))).
            attr("data-toggle", "popover").
            attr("data-trigger", "hover").
            attr("data-placement", "top").
            attr("title", title).
            popover();
        }
      });
    });
  }

  initDossierReferences() {
    $(this.element).find("span.dossier-reference").each(function(i, e) {
      var locale = $("body").data('locale');
      e = $(e);
      if(locale == 'de'){
        e.replaceWith("<a href=\"/dossiers/"+ e.data("term") +"\">"+ e.text() +"</a>")
      } else {
        e.replaceWith("<a href=\"/"+ locale  + "/dossiers/"+ e.data("term") +"\">"+ e.text() +"</a>")
      }
    });
  }

  initPersonReferences() {
    var self = this;
    self.personRegister.ready(function() {
      $(self.element).find("span.person-reference").each(function(i, e) {
        e = $(e);
        var term = self.personRegister.term(e.data("term"));
        if (term) {
          e.attr("data-content", term.title).
            attr("data-toggle", "popover").
            attr("data-trigger", "hover").
            attr("data-placement", "top").
            popover();
        }
      });
    });
  }
}
