import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    selectedKind: String
  }

  interactiveSearch;

  connect() {
    var search = $(this.element);
    var kindSelector = new KindSelector(search.prev(".kind-selector"))
    this.interactiveSearch = InteractiveSearch(search, kindSelector);
    if (this.selectedKindValue) {
      this.interactiveSearch.kindSelector.setKind(this.selectedKindValue)
    }
  }
}
