import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    (new InfiniteScroller("#result")).enable();
  }
}

class InfiniteScroller {
  constructor(containerSelector) {
    this.containerSelector = containerSelector
    this.buffer = 400
    this.isLoading = false
    this.paginationSelector = ".pagination"
    this.spinnerTemplate = Handlebars.compile(`
<ul class="bokeh">
  <li></li>
  <li></li>
  <li></li>
  <li></li>
</ul>
`)
  }

  enable() {
    this.hidePaginator();
    $(window).scroll(this.onScroll.bind(this))
  }

  onScroll() {
    if (this.isLoadNextPage()) { this.loadNextPage() }
  }

  isLoadNextPage() {
    let bufferZoneIsVisible = $(window).scrollTop() >= $(document).height() - $(window).height() - this.buffer
    return bufferZoneIsVisible && !this.isLoading
  }

  loadNextPage() {
    let href = this.nextPageHref()
    if (href) { this.loadPage(href) }
  }

  loadPage(href) {
    this.isLoading = true;
    var spinner = $(this.spinnerTemplate()).appendTo(this.containerSelector)
    $(this.paginationSelector, this.containerSelector).replaceWith(spinner)

    $.get(href, data => {
      spinner.replaceWith($(this.containerSelector, data).children());
      this.hidePaginator();
      this.isLoading = false;
    });
  }

  nextPageHref() {
    return $("a[rel=next]", this.containerSelector).attr("href")
  }

  hidePaginator() {
    $(this.paginationSelector, this.containerSelector).hide();
  }
}
