import { Controller } from "@hotwired/stimulus"
import { CachedJSON } from "~/src/office/cached_json"
import { make_tree } from "~/src/office/tree"

export default class extends Controller {
  static targets = [ "chapterSelect", "headingSelect" ]
  static optionsTemplate = Handlebars.compile('\
    {{#each nodes}} \
        <option value="{{id}}"> \
          {{#times depth}}-{{/times}} {{grade_text}} {{translate "text_"}} \
        </option> \
    {{/each}} \
  ');

  connect() { }

  updateHeadings(event) {
    var self = this;
    this.getNodesById($(this.chapterSelectTarget).find(":selected").val(), function(nodes) {
      $(self.headingSelectTarget).html(self.constructor.optionsTemplate({ nodes: nodes }));
    });
  }

  getNodesById(id, callback) {
    CachedJSON.fetch("/table_of_contents.json", function(treeData) {
      var tree = make_tree(treeData);

      var chapterNode = tree.findBy({id: id}, true);
      callback(tree.mapNode(chapterNode, function() {
        return {
          id: this.id,
          depth: (this.depth - chapterNode.depth - 1),
          grade_text: this.grade_text,
          text_de: this.text_de,
          text_fr: this.text_fr
        };
      }));

    });
  }
}
