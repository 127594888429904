import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if($('#user_email').length > 0 && $('#user_email')[0].defaultValue) {
      $('.container > div').addClass('animated shake');
      $('.avatar').addClass('angry');
    }
  }
}
