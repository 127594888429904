"use strict";

// This array covers all vendor specific transition-end events
// https://developer.mozilla.org/en-US/docs/Web/Events/transitionend
var transitionEnd = [
  "webkitTransitionEnd",
  "transitionend",
  "oTransitionEnd",
  "otransitionend"
].join(" ");

// This element represents the document root
var root = $(":root");

function isEscape(event) { return event.keyCode === 27; }

/*
  The MorphButton module provides a way of creating a button which content morphs to a
  full size overlay like element.
  The object returned by the initialized provides an `open` and `close` function.
  By default an element with the class `morph-close` is used to attach the handler for closing
  the morph content.
  By default an element with the class `morph-content` is used for the content which appears
  in the overlay.
 */
export function MorphButton(target, options) {
  options = $.extend({
    closeEl: ".morph-close",
    contentEl: ".morph-content"
  }, options);

  var content = $(options.contentEl, target);
  target = $(target);
  target.addClass("morph-closed");
  target.removeClass("morph-close-immediately");

  function setDimensions() {
    var offset = target.offset();
    var top = offset.top - $(window).scrollTop();
    var left = offset.left;
    content.width(target.width())
           .height(target.height())
           .css("left", left)
           .css("top", top);
  }

  function open() {
    root.addClass("morph-open");
    setDimensions();
    target.removeClass("morph-closed");
    content.addClass("active");

    content.delay(1)
           .queue(function(next) {
              target.addClass("morph-opening");
              next();
           });
  }

  function close(immediately) {
    setDimensions();
    target.addClass("morph-closing");
    target.removeClass("morph-opened");
  }

  function handleOpen(event) {
    if($(event.currentTarget).hasClass("morph-closed")) {
      event.preventDefault();
      open();
    }
  }

  function handleClose(event) {
    event.stopPropagation();
    event.preventDefault();
    close();
  }

  function handleKeyClose(event) {
    if(isEscape(event)){
      $(".morph-opened " + options.closeEl).click();
    }
  }

  function handleOriginClose(event) {
    var target = $(event.target);
    if(!$(".morph-opened").length) {
      return;
    }
    if(!target.closest(options.contentEl).length && !target.is(content)) {
      $(options.closeEl, event.target).click();
    }
  }

  function afterOpen() {
    target.addClass("morph-opened");
    target.removeClass("morph-opening");
  }

  function afterClose(event) {
    target.addClass("morph-closed");
    target.removeClass("morph-closing");
    content.removeClass("active");
    root.removeClass("morph-open");
  }

  function onTransitionEnd(event) {
    // The transition-end event is fired for every property that has been transitioned
    // So we have to check if the width property is involved in these transitions
    if(event.originalEvent.propertyName !== "width") {
      return false;
    }
    if(target.hasClass("morph-opening")) {
      afterOpen(event);
    } else if (target.hasClass("morph-closing")) {
      afterClose();
    }
  }

  // We don't want the morph button behavior if there is no morph content
  // This way we are able to use the morph button for simple links and we do not have to copy the whole styling
  if(!content.length) { return false; }

  target.on("click", handleOpen)
        .on("click", options.closeEl, handleClose)
        .on(transitionEnd, onTransitionEnd);

  $(document).on("keyup", handleKeyClose)
             .on("click", handleOriginClose)
             .on("turbo:load", function() {
                root.removeClass("morph-open");
                if($(".morph-opened").length) {
                  root.addClass("morph-open");
                }
              });

  return Object.freeze({ open: open, close: close });
}
